import React from 'react';

import Layout from 'layouts/Layout';
import SEO from 'components/seo/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="Teala | 404" />
    <h1>Strona nie została znaleziona</h1>
  </Layout>
);

export default NotFoundPage;
